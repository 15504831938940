<template>
  <div class="cont main sc-width">
<!--    <div class="top">-->
<!--      <div class="back-request" @click="back('document')">-->
<!--        <div class="pic"><img src="@/assets/back.png" alt="" /></div>-->
<!--        <div class="word">Return to List</div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="box">
      <div class="title">Add Document</div>

      <Form
        ref="create"
        :model="create"
        :rules="createRules"
        class="document-form"
        :label-width="200"
      >
        <FormItem label="Document Name" prop="name">
          <Input v-model="create.name" />
        </FormItem>

        <FormItem label="Type" prop="type">
          <Select v-model="create.type">
            <Option v-for="item in document_type" :key="item" :value="item">{{
              item
            }}</Option>
          </Select>
        </FormItem>

        <FormItem label="Extra Type" prop="extraType">
          <Select v-model="create.extraType">
            <Option
              v-for="item in document_extra_type"
              :key="item"
              :value="item"
              >{{ item }}</Option
            >
          </Select>
        </FormItem>

        <FormItem label="File" prop="url">
          <div v-if="!(file || url)" class="upload" @click="$refs.file.click()">
            <div class="upload-inner">
              <input
                @change="handleUpload"
                v-show="false"
                type="file"
                ref="file"
              />
              <Icon class="icon" type="ios-cloud-upload" size="52"></Icon>
              <p>Click here to upload</p>
            </div>
          </div>
          <div class="upload" v-else>
            <div v-if="uplodLoading">
              Uploading...
            </div>
            <div v-else>
              <div v-if="url">
                <Alert type="success">File upload successful</Alert>
                <Button @click="removeFile">Choose Again</Button>
              </div>
            </div>
          </div>
        </FormItem>

        <FormItem>
          <Button :loading="createLoading" type="primary" @click="createSubmit"
            >Submit</Button
          >
          <Button
            type="warning"
            @click="back('document')"
            style="width:200px;margin:30px 20px;color: #f57f45;background: #fff"
          >Cancel</Button
          >
        </FormItem>
      </Form>
    </div>
    <div class="form-foot"></div>
  </div>
</template>

<script>
import api from "@/api";
const { _createDocument, _queryDocumentRelatedInfo } = api;
import S3 from "aws-s3";

const uploader = new S3({
  bucketName: "connexima",
  dirName: "documents",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});

export default {
  name: "documents",
  created() {
    _queryDocumentRelatedInfo()
      .then(response => {
        const {
          data: { document_extra_type, document_type }
        } = response;

        this.document_extra_type = document_extra_type;
        this.document_type = document_type;
      })
      .catch(error => {
        console.log(error);
      });
  },
  data() {
    return {
      document_type: [],
      document_extra_type: [],
      file: null,
      url: "",
      uplodLoading: false,
      create: {
        name: "",
        type: "",
        extraType: ""
      },
      createLoading: false,
      createRules: {
        name: [
          {
            required: true
          }
        ],
        type: [
          {
            required: true
          }
        ],
        extraType: [
          {
            required: true
          }
        ],
        url: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.url) {
                callback();
              } else {
                callback(new Error("file is required"));
              }
            }
          }
        ]
      }
    };
  },

  methods: {
    back(type) {
      this.$router.push({ path: "/company-profile", query: { type: type } });
    },
    removeFile() {
      this.file = null;
      this.url = "";
    },

    handleUpload(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 20MB");
        } else {
          this.file = file;
          this.upload();
        }
      }
    },

    upload() {
      this.uplodLoading = true;
      uploader
        .uploadFile(this.file)
        .then(data => {
          this.url = data.location;
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodLoading = false;
          this.$refs.create.validateField("url");
        });
    },

    createSubmit() {
      this.$refs.create.validate(valid => {
        if (valid) {
          this.createLoading = true;
          _createDocument({
            name: this.create.name,
            type: this.create.type,
            url: this.url,
            extra: {
              type: this.create.extraType
            }
          })
            .then(() => {
              this.$Message.success("Success");
              this.back("document");
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}
.cont {
  margin: 78px auto 0 auto;
  /*background-image: url('../../assets/my-profile.png');*/
  .box {
    padding-bottom: 20px;
    margin-bottom: 30px;
    margin-top: 90px;
    /*width: 400px;*/
    /*box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;*/
    position: relative;
    top: 40px;
    background: #fff;
    border-radius: 15px;
    .document-form {
      width: 1130px;
      margin: 30px auto 0 auto;
    }
    .title {
      height: 60px;
      background: #fff;
      /*opacity: 0.9;*/
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      font-size: 20px;

      font-weight: 700;
      color: #292929;
      line-height: 18px;
      padding-top: 20px;
      display: flex;
      /*flex-direction: column;*/
      justify-content: center;
    }
    .upload {
      width: 70%;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 3px;
      padding: 20px;
      cursor: pointer;

      .upload-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .icon {
          color: @primary-color;
        }
      }
    }
  }
}
.top {
  .back-request {
    width: 200px;
    height: 40px;
    background: #ef7f21;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    top: 39px;
    .pic {
      width: 8px;
      height: 10px;
      margin-right: 10px;
      margin-top: 2px;
      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 8px;
        left: 0px;
      }
    }

    .word {
      position: relative;
      top: 9px;
      font-size: 18px;

      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
    }
  }
}
  .form-foot{
    height: 40px;
  }
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}

.document-form >>> .ivu-form-item-label {
  font-size: 16px;

  font-weight: 400;
  color: #292929;
  line-height: 18px;
}
.ivu-steps {
  width: 1150px;
  margin: 0 auto;
  justify-content: space-between;
}
.ivu-btn-primary {
  width: 200px;
  height: 34px;
  background: linear-gradient(90deg, #f5a210, #ef8126);
  border-radius: 5px;
  font-size: 18px;

  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}
.ivu-form-item-content {
  margin-bottom: 40px;
}
.ivu-input-wrapper {
  width: 70%;
}
.ivu-select {
  width: 70%;
}
</style>
